import {IDataResponse} from '../../types/cart';

const storeName = 'Cart';
const dbName = 'CartCache';
const key: string = 'cart';
const TTL = 1000 * 60;

export class CartCacheService {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  private db: IDBDatabase | undefined;
  private hadError = false;

  private async getDB(): Promise<IDBDatabase | undefined> {
    if (this.db || this.hadError) {
      return this.db;
    }

    this.db = await new Promise((resolve) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(storeName, {keyPath: 'key'});
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = () => {
        this.hadError = true;
        resolve(undefined);
      };
    });
    return this.db;
  }

  public async get(): Promise<IDataResponse | undefined> {
    const db = await this.getDB();
    if (!db) {
      return;
    }
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);

    return new Promise((resolve) => {
      request.onsuccess = (event) => {
        try {
          const result = (event.target as IDBRequest).result;
          if (!result?.value) {
            return resolve(undefined);
          }

          const {timestamp, cartData} = getCacheValue(result.value);

          if (hasExpired(timestamp)) {
            return resolve(undefined);
          }

          resolve(cartData);
        } catch {
          resolve(undefined);
        }
      };

      request.onerror = () => {
        resolve(undefined);
      };
    });
  }

  public async set(value: IDataResponse): Promise<void> {
    const db = await this.getDB();
    if (!db) {
      return undefined;
    }
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const valueString = JSON.stringify({cartData: value, timestamp: Date.now()});
    const keyValue = {key, value: valueString};
    const request = objectStore.put(keyValue);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        resolve(undefined);
      };
    });
  }
}

function getCacheValue(value: string): {cartData: IDataResponse; timestamp: number} {
  return JSON.parse(value);
}

function hasExpired(timestamp: number) {
  return Date.now() - timestamp > TTL;
}
